<template>
  <RRSheader />
  <page-title :in_submission="true">
    <template v-slot:titlePrefix>LIMU Research </template>
    <template v-slot:mainTitle>Portal </template>
  </page-title>
  <div class="bg-soft-info vh-100">
    <div class="about p-5 text-center">
      <!-- CTA -->
      <div class="container content-space-1">
        <div class="w-lg-75 mx-lg-auto">
          <div class="row align-items-sm-center">
            <div class="col-sm-12">
              <div class="ps-sm-5">
                <div class="mb-sm-5">
                  <h3>Coming soon...</h3>
                </div>
              </div>
            </div>
            <!-- End Col -->
          </div>
          <!-- End Row -->
        </div>
      </div>
      <!-- End CTA -->
    </div>
  </div>
  <logIn-modal />
  <signUp-Modal />
  <popUpMessage />
</template>

<script>
import RRSheader from "@/components/RRSheader.vue";
import pageTitle from "@/components/pageTitle";
import signUpModal from "@/components/signUpModal.vue";
import logInModal from "@/components/logInModal.vue";

export default {
  name: "About",
  components: {
    RRSheader,
    pageTitle,
    signUpModal,
    logInModal,
  },
};
</script>
